"use client";
import { messageListener } from "@global/messaging";
import { GetUserKey, getUser } from "@queries/getUser";
import { useQueryClient } from "@tanstack/react-query";
import { calculatePosition } from "@utils/calculatePosition";
import { isBrowser } from "@utils/isBrowser";
import { getItem, setItem } from "@utils/localStorage";
import { parseJwt } from "@utils/parseJwt";
import { useEffect, useState } from "react";
import { useAuth } from "./useAuth";
import { useConfig } from "./useConfig";
import useCustomRouter from "./useCustomRouter";
import { useProductStore } from "@stores/product";
import { useGetPaymentSessionById } from "@queries/paymentSession/getPaymentSessionById";

type containerPosition =
  | { mode: "center"; rect: DOMRect }
  | { mode: "element"; rect: DOMRect };

const authenticationFlow = ["/login", "/verification", "/enter-number"];
const authenticatedPages = [
  "/empty-dashboard",
  "/pay",
  "/pay/no-payment",
  "/pay/add-card",
  "/share-and-earn",
  "/enter-fullname",
];

const safeRedirect = (path: string): boolean | string => {
  if (!isBrowser()) return false;

  const currentPath = window.location.pathname;
  const searchParamsMap = new URLSearchParams(window.location.search);
  const redirect = searchParamsMap.getAll("redirect").at(-1);
  const isAuthenticationFlow = authenticationFlow.includes(currentPath);

  if (isAuthenticationFlow && authenticatedPages.includes(path)) {
    searchParamsMap.set("redirect", path);

    return window.location.pathname + "/?" + searchParamsMap.toString();
  }

  if (
    currentPath.startsWith(path) ||
    (isAuthenticationFlow && path === redirect)
  )
    return false;

  return true;
};

const useListenHost = () => {
  const queryClient = useQueryClient();
  const router = useCustomRouter();
  const { setUser, user, isAuthenticated } = useAuth();
  const { setConfig } = useConfig();
  const { setProduct, product } = useProductStore();

  const [position, setPosition] = useState<containerPosition>({
    mode: "center",
    rect: {} as DOMRect,
  });

  const session = useGetPaymentSessionById(
    { session_id: product?.session_id! },
    { enabled: product?.session_id ? product?.session_id?.length > 0 : false },
  );

  useEffect(() => {
    const handleMessage = (event: MessageEvent<HostIFrameMessage>) => {
      switch (event.data.type) {
        case "routing":
          const redirect = safeRedirect(event.data.path);

          if (event.data.path && typeof redirect === "boolean" && redirect) {
            router.prefetch(event.data.path ?? "/empty-dashboard");
            router.push(event.data.path ?? "/empty-dashboard");
          }

          if (typeof redirect === "string") {
            router.prefetch(redirect);
            router.replace(redirect);
          }
          break;
        case "token":
          const userID = parseJwt<{ sub: string }>(
            event.data.access_token,
          )?.sub;
          const access_token = getItem("access_token");
          const currentUserID = access_token
            ? parseJwt<{ sub: string }>(access_token)?.sub
            : "";

          setItem("access_token", event.data.access_token);
          event.data.refresh_token &&
            setItem("refresh_token", event.data.refresh_token);

          if (userID !== currentUserID) {
            getUser()
              .then((res) => {
                queryClient.setQueryData(GetUserKey, res);

                setUser(res.data);
              })
              .catch((err) => {
                console.error(err);
              });
          }

          break;
        case "add-products":
          setProduct(event.data.cart[0]);
          break;
        case "init":
          setItem("api_key", event.data.api_key);
          setItem("org_code", event.data.org_code);

          if (event.data.config) {
            setConfig(event.data.config);
          }
          break;
        case "reposition":
        case "position": {
          // const cards = user?.cards.filter((i) => i.type === "card") ?? [];

          queryClient.refetchQueries({
            stale: true,
          });

          if (event.data.dimensions) {
            setItem("dimensions.height", String(event.data.dimensions.height));
            setItem("dimensions.width", String(event.data.dimensions.width));
          }

          /*  if (isAuthenticated && cards.length > 0) {
            calculatePosition(
              event.data.rect ?? position.rect,
              document.getElementById("root"),
            );
          } else { */
          calculatePosition(
            event.data.position ??
              (position.mode === "center"
                ? position.mode
                : (event.data.rect ?? position.rect)),
            document.getElementById("root"),
          );
          // }
          /* TODO: get precise position of container from calculatePosition */
          if (event.data.position && event.data.rect) {
            setPosition(
              event.data.position === "center"
                ? { mode: "center", rect: event.data.rect }
                : { mode: "element", rect: event.data.rect },
            );
          }

          break;
        }
        case "config":
          setConfig(event.data.payload);
          break;
        case "installed":
        case "login":
        case "authenticate":
        case "logout":
        case "closeIframe":
        case "reopenIframe":
        case "payment":
        case "product-info":
        case "epn-change-height":
        case "epn-payment-status":
        case "add-new-card":
          break;
      }
    };

    const remove = messageListener(handleMessage);

    return () => {
      remove();
    };
  }, [
    isAuthenticated,
    router,
    position.mode,
    position.rect,
    queryClient,
    setUser,
    user?.cards,
    setConfig,
  ]);

  return {
    position,
  };
};

export default useListenHost;
